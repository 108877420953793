import React from "react";
import TcMainTable from "../../../../Layout/TcMainTable";

const Purchase = () => {
    return (
        <>
            <div className="page-content">
                <TcMainTable />
            </div>
        </>
    );
};

export default Purchase;
