import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined, SnippetsOutlined, SettingOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Switch, Button, Select, Input, DatePicker } from "antd";
import "simplebar-react/dist/simplebar.min.css";
import { withProfile } from "../../contexts/profileContext";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import axios from "axios";

const InvoiceTable = ({ validatedProfile, mainTableData, subTableData, fetchData }) => {
    const onChangeInvoice = (checked) => {
        console.log(`Switch to ${checked}`);
    };
    const navigate = useNavigate();
    let location = useLocation();
    const { pathWithoutSlash, currentPath } = location.state || {};

    const salesHeadingLedger =
        currentPath === "/tcsales" ? { Ledger: "Sales Ledger" } : { Ledger: "Purchase Ledger", VNo: "Supplier Invoice No." };
    const salesJsonHeadingLedger = currentPath === "/tcsales" ? { Ledger: "salesLedger" } : { Ledger: "purchaseLedger" };

    const gobackpage = () => {
        navigate(currentPath);
    };
    const [purchasevoucherType, setPurchasevoucherType] = useState([]);
    const [partyAcNameOptions, setPartyAcNameOptions] = useState([]);
    const [ledgeroption, setLedgeroption] = useState([]);
    const [stockitemNameOptions, setStockitemNameOptions] = useState([]);

    const getUniqueOptions = (data, key, filterKey, filterValue) => {
        return [
            ...new Set(
                data?.[key]
                    ?.map((element) => (filterKey ? (element[filterKey] === filterValue ? element.name : null) : element.name))
                    .filter(Boolean)
            ),
        ];
    };

    useEffect(() => {
        if (currentPath === "/tcsales") {
            setPurchasevoucherType(getUniqueOptions(mainTableData, "vouchertype").filter((type) => type.includes("Sales")));
            setPartyAcNameOptions(getUniqueOptions(subTableData, "ledgermaster", "under", "Sales Accounts"));
        } else if (currentPath === "/tcpurchase") {
            setPurchasevoucherType(getUniqueOptions(mainTableData, "vouchertype").filter((type) => type.includes("Purchase")));
            setPartyAcNameOptions(getUniqueOptions(subTableData, "ledgermaster", "under", "Purchase Accounts"));
        }
        setLedgeroption(getUniqueOptions(mainTableData, "ledgermaster"));
        setStockitemNameOptions(getUniqueOptions(subTableData, "stockitem"));
    }, [mainTableData, subTableData]);

    //--------------------------------------------------------------------

    const [formData, setFormData] = useState({
        voucherType: "",
        voucherNo: "",
        voucherDate: "",
        partyName: "",
        gstNumber: "",
        fileName: "",
        [salesJsonHeadingLedger.Ledger]: "",
    });

    const handleChange = (key, value) => {
        setFormData((prev) => ({ ...prev, [key]: value }));
    };
    //--------------------------------------------------------------------

    const [itemData, setItemData] = useState([{ id: 1, itemName: "", qty: "", rate: "", amount: "" }]);

    const handleItemInputChange = (index, field, value) => {
        const newData = [...itemData];
        newData[index][field] = value;
        if (field === "qty" || field === "rate") {
            const qty = parseFloat(newData[index].qty) || 0;
            const rate = parseFloat(newData[index].rate) || 0;
            newData[index].amount = (qty * rate).toFixed(2); // Calculate and set amount
        }
        setItemData(newData);
    };

    const addItemRow = () => {
        const newRow = {
            id: itemData.length + 1,
            itemName: "",
            qty: "",
            rate: "",
            amount: "",
        };
        setItemData([...itemData, newRow]);
    };

    const deleteItemRow = (index) => {
        const newData = itemData.filter((_, idx) => idx !== index);
        setItemData(newData);
    };

    const itemTotalAmount = itemData.reduce((total, row) => total + parseFloat(row.amount || 0), 0);
    //--------------------------------------------------------------------

    const [ledgerData, setLedgerData] = useState([{ id: 1, ledgerName: "", amount: "" }]);

    const handleLedgerInputChange = (index, field, value) => {
        const newData = [...ledgerData];
        newData[index][field] = value;
        setLedgerData(newData);
    };

    const addLedgerRow = () => {
        const newRow = {
            id: ledgerData.length + 1,
            ledgerName: "",
            amount: "",
        };
        setLedgerData([...ledgerData, newRow]);
    };

    const deleteLedgerRow = (index) => {
        const newData = ledgerData.filter((_, idx) => idx !== index);
        setLedgerData(newData);
    };

    // Calculate total amount
    const ledgerTotalAmount = ledgerData.reduce((total, row) => total + parseFloat(row.amount || 0), 0);

    //--------------------------------------------------------------------

    const [taxLedgerData, setTaxLedgerData] = useState([
        { id: 1, taxLedgerName: "CGST", taxDescription: "", taxAmount: "" },
        { id: 2, taxLedgerName: "SGST", taxDescription: "", taxAmount: "" },
    ]);

    // Handle input change
    const handleTaxInputChange = (index, field, value) => {
        const newData = [...taxLedgerData];
        newData[index][field] = value;
        setTaxLedgerData(newData);
    };

    // Calculate total tax amount
    const totalTaxAmount = parseFloat(taxLedgerData.reduce((total, row) => total + parseFloat(row.taxAmount || 0), 0));

    useEffect(() => {
        if (!mainTableData?.itemgstdetails) return;

        let totalCGST = 0;
        let totalSGST = 0;

        itemData.forEach((item) => {
            mainTableData.itemgstdetails.forEach((row) => {
                if (item.itemName === row.itemName) {
                    totalCGST += (parseFloat(row.cgst || 0) * parseFloat(item.rate || 0)) / 100;
                    totalSGST += (parseFloat(row.sgst || 0) * parseFloat(item.rate || 0)) / 100;
                }
            });
        });
        setTaxLedgerData((prev) =>
            prev.map((tax) => {
                if (tax.taxLedgerName === "CGST") {
                    return { ...tax, taxAmount: totalCGST.toFixed(2) };
                } else if (tax.taxLedgerName === "SGST") {
                    return { ...tax, taxAmount: totalSGST.toFixed(2) };
                }
                return tax;
            })
        );
    }, [mainTableData, itemData]);

    //--------------------------------------------------------------------

    const handleSave = async () => {
        function generateUniqueCode() {
            const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
            let code = "";

            for (let i = 0; i < 10; i++) {
                const randomIndex = Math.floor(Math.random() * characters.length);
                code += characters[randomIndex];
            }

            return code;
        }
        const generateUniqueCodeNO = generateUniqueCode();
        console.log("Stored Data:", formData);
        console.log("itemData Data:", itemData);
        console.log("ledgerData Data:", ledgerData);
        console.log("taxLedgerData Data:", taxLedgerData);
        console.log("pathWithoutSlash Data:", pathWithoutSlash);

        const data = {
            user_master_id: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
            company_master_id: validatedProfile.companyId,
            doc_id: generateUniqueCodeNO,
            voucher_type: formData.voucherType,
            voucher_no: formData.voucherNo,
            invoice_date: formData.voucherDate,
            party_ac_name: formData.partyName,
            gst_no: formData.gstNumber,
            ledger_type: formData[salesJsonHeadingLedger.Ledger],
            total_amount: itemTotalAmount + ledgerTotalAmount + totalTaxAmount,

            itemData: itemData,
            ledgerData: ledgerData,
            taxLedgerData: taxLedgerData,
            pathWithoutSlash: pathWithoutSlash,
        };

        console.log("data", data);

        // Check if all required values are available
        const requiredFields = [
            "voucher_type",
            "voucher_no",
            "invoice_date",
            "party_ac_name",
            "gst_no",
            "ledger_type",
            "total_amount",

            "itemData",
            "ledgerData",
            "taxLedgerData",
        ];

        const isAllFieldsAvailable = requiredFields.every((field) => {
            const value = data[field];
            if (Array.isArray(value)) {
                return value.length > 0; // Ensure arrays are not empty
            }
            return value !== undefined && value !== null && value !== "";
        });

        // Check if each item in itemData has the required fields
        const requiredItemFields = ["itemName", "qty", "rate"];
        const isItemDataValid = data.itemData.every((item) => {
            return requiredItemFields.every((field) => {
                const value = item[field];

                // Check if the value is not undefined, null, or an empty string
                if (value === undefined || value === null || value === "") {
                    return false;
                }

                // Additional check for qty and rate to ensure they are not 0
                if ((field === "qty" || field === "rate") && (value === 0 || value === "0")) {
                    return false;
                }

                return true;
            });
        });

        const requiredGSTFields = ["taxAmount", "taxLedgerName"];
        const isGSTDataValid = data.taxLedgerData.every((item) => {
            return requiredGSTFields.every((field) => {
                const value = item[field];
                // Check if the value is not undefined, null, or an empty string
                if (value === undefined || value === null || value === "") {
                    return false;
                }

                // Additional check for qty and rate to ensure they are not 0
                if (field === "taxAmount" && (value === 0 || value === "0")) {
                    return false;
                }

                return true;
            });
        });
        if (!isAllFieldsAvailable) {
            alert("Some required fields are missing or empty.");
            return;
        }
        if (!isItemDataValid) {
            alert("Some items are missingalert required fields.");
            return;
        }
        if (!isGSTDataValid) {
            alert("Some GST are missing or 0 required fields.");
            return;
        }
        console.log("data", data);

        try {
            const mainTableData = {
                userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
                companyId: validatedProfile.companyId,
                fileDetails: {
                    doc_id: generateUniqueCodeNO,
                    filetype: "invoice",
                    imagetype: "Manually Insert",
                    convert_status: "View",
                    name: formData.fileName,
                },
                tableName: pathWithoutSlash,
            };

            const mainTableDataresponse = await axios.post(`${process.env.REACT_APP_API_URL}/tcinsertfiledata`, mainTableData, {
                headers: { "Content-Type": "application/json" },
            });
            // console.log("mainTableDataresponse", mainTableDataresponse);

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/tcInsertFilnalJson`, data, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                console.log("Response:", response.data);
                fetchData();
                navigate(currentPath);
            }
        } catch (error) {
            console.error("Error saving data:", error);
        }
    };

    return (
        <>
            <div className="invoice-outer">
                <div className="invoice-inner">
                    <div className="invoice-heading-outer">
                        <div className="invoice-heading">
                            <div className="beck-btn">
                                <Button onClick={gobackpage}>
                                    <ArrowLeftOutlined />
                                </Button>
                                <p className="invoice_heading_text">
                                    <SnippetsOutlined /> Item Invoice
                                </p>
                            </div>
                            <div>
                                Company Name:
                                <span style={{ color: "#0bb197" }}> {validatedProfile.companyName.toUpperCase()}</span>
                            </div>
                            <div className="invoice-btn">
                                <label htmlFor="" className="invoice-common">
                                    Accounting Invoice
                                </label>
                                <Switch className="invoice-common" defaultChecked onChange={onChangeInvoice} />
                                <label htmlFor="" className="invoice-common">
                                    Item Invoice
                                </label>
                                <Button className="invoice-common Configration-btn" type="primary">
                                    <SettingOutlined /> Configration
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="invoice_body_inner">
                        <div className="left_side_voucher manual_ivc_card">
                            <div className="left_first_card">
                                <div className="input_div">
                                    <div className="voucher_type">
                                        <label>Voucher Type</label>
                                        <Select className="voucher_type_select" onChange={(value) => handleChange("voucherType", value)}>
                                            {Object.entries(purchasevoucherType).map(([key, value]) => (
                                                <Select.Option key={key} value={value}>
                                                    {value}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </div>
                                    <div className="voucher_type">
                                        <label>Voucher No.</label>
                                        <Input
                                            className="voucher_no_select"
                                            type="text"
                                            onChange={(e) => handleChange("voucherNo", e.target.value)}
                                        />
                                    </div>
                                    <div className="voucher_type">
                                        <label>Voucher Date</label>
                                        <DatePicker
                                            className="voucher_no_select"
                                            type="date"
                                            value={formData.voucherDate ? dayjs(formData.voucherDate) : null}
                                            onChange={(date, dateString) => handleChange("voucherDate", dateString)}
                                            placeholder="YYYY-MM-DD"
                                        />
                                    </div>
                                </div>
                                <div className="input_div">
                                    <div className="voucher_type">
                                        <label>Party Name</label>
                                        <div className="party_name_div">
                                            <Select className="party_name_select" onChange={(value) => handleChange("partyName", value)}>
                                                {Object.entries(ledgeroption).map(([key, value]) => (
                                                    <Select.Option key={key} value={value}>
                                                        {value}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                            <Button className="party_name_btn">
                                                <PlusCircleOutlined />
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="voucher_type">
                                        <label>GST Number</label>
                                        <div className="party_name_div">
                                            <Input
                                                className="party_name_select"
                                                type="text"
                                                value={formData.gstNumber}
                                                onChange={(e) => handleChange("gstNumber", e.target.value.toUpperCase())}
                                            />
                                            <Button className="party_name_btn">Details</Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="input_div">
                                    <div className="voucher_type">
                                        <label>{salesHeadingLedger.Ledger}</label>
                                        <div className="party_name_div">
                                            <Select
                                                className="voucher_type_select"
                                                onChange={(value) => handleChange(salesJsonHeadingLedger.Ledger, value)}
                                            >
                                                {Object.entries(partyAcNameOptions).map(([key, value]) => (
                                                    <Select.Option key={key} value={value}>
                                                        {value}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="voucher_type">
                                        <label>File Name</label>
                                        <div className="party_name_div">
                                            <Input
                                                className="party_name_select"
                                                type="text"
                                                onChange={(e) => handleChange("fileName", e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="left_first_card">
                                <p className="left_card_titel">Item Details</p>
                                <table className="s_transaction-content-box-table-border">
                                    <thead>
                                        <tr className="table_heading_tr common_tr">
                                            <th className="s_tw-5 comman_class_table_th">Sr .No</th>
                                            <th className="s_tw-35 comman_class_table_th">Item Name</th>
                                            <th className="s_tw-10 comman_class_table_th">Qty</th>
                                            <th className="s_tw-10 comman_class_table_th">Rate</th>
                                            <th className="s_tw-amount comman_class_table_th">Amount</th>
                                            <th className="s_action-column s_text-center comman_class_table_th">
                                                Action{" "}
                                                <span onClick={addItemRow} type="primary" className="add_row_btn">
                                                    +
                                                </span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {itemData.map((row, index) => (
                                            <tr key={index} className="table_body_tr common_tr">
                                                <td className="s_tw-5 comman_class_table_th">{row.id}</td>
                                                <td className="s_tw-35 comman_class_table_th">
                                                    <Select
                                                        className="voucher_type_select"
                                                        value={row.itemName}
                                                        onChange={(value) => handleItemInputChange(index, "itemName", value)}
                                                    >
                                                        {Object.entries(stockitemNameOptions).map(([key, value]) => (
                                                            <Select.Option key={key} value={value}>
                                                                {value}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </td>
                                                <td className="s_tw-10 comman_class_table_th">
                                                    <Input
                                                        type="number"
                                                        value={row.qty}
                                                        onChange={(e) => handleItemInputChange(index, "qty", e.target.value)}
                                                    />
                                                </td>
                                                <td className="s_tw-10 comman_class_table_th">
                                                    <Input
                                                        type="number"
                                                        value={row.rate}
                                                        onChange={(e) => handleItemInputChange(index, "rate", e.target.value)}
                                                    />
                                                </td>
                                                <td className="s_tw-amount comman_class_table_th">
                                                    <Input type="text" value={row.amount} readOnly />
                                                </td>
                                                <td className="s_action-column s_text-center comman_class_table_th">
                                                    <span
                                                        type="danger"
                                                        style={{ backgroundColor: "red" }}
                                                        danger
                                                        onClick={() => deleteItemRow(index)}
                                                        disabled={itemData.length <= 1}
                                                        className="add_row_btn"
                                                    >
                                                        X
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                        <tr className="table_total_tr common_tr">
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className="s_action-column s_text-center "></td>
                                            <td className="s_tw-amount comman_class_table_th">
                                                <span className="table_total">
                                                    <span>Total:</span> <span>{itemTotalAmount.toFixed(2)}</span>
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="left_first_card">
                                <p className="left_card_titel">Ledger Details</p>
                                <table className="s_transaction-content-box-table-border">
                                    <thead>
                                        <tr className="table_heading_tr common_tr">
                                            <th className="s_tw-5 comman_class_table_th">Sr .No</th>
                                            <th className="s_tw_65 comman_class_table_th">Ledger Name</th>
                                            <th className="s_tw-amount comman_class_table_th">Amount</th>
                                            <th className="s_action-column s_text-center comman_class_table_th">
                                                Action{" "}
                                                <span onClick={addLedgerRow} type="primary" className="add_row_btn">
                                                    +
                                                </span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ledgerData.map((row, index) => (
                                            <tr key={index} className="table_body_tr common_tr">
                                                <td className="s_tw-5 comman_class_table_th">{row.id}</td>
                                                <td className="s_tw_65 comman_class_table_th">
                                                    <Select
                                                        className="voucher_type_select"
                                                        onChange={(value) => handleLedgerInputChange(index, "ledgerName", value)}
                                                    >
                                                        {Object.entries(ledgeroption).map(([key, value]) => (
                                                            <Select.Option key={key} value={value}>
                                                                {value}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </td>
                                                <td className="s_tw-amount comman_class_table_th">
                                                    <Input
                                                        type="number"
                                                        value={row.amount}
                                                        onChange={(e) => handleLedgerInputChange(index, "amount", e.target.value)}
                                                    />
                                                </td>
                                                <td className="s_action-column s_text-center comman_class_table_th">
                                                    <span
                                                        onClick={() => deleteLedgerRow(index)}
                                                        disabled={ledgerData.length <= 1}
                                                        style={{ backgroundColor: "red" }}
                                                        type="primary"
                                                        danger
                                                        className="add_row_btn"
                                                    >
                                                        X
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                        <tr className="table_total_tr common_tr">
                                            <td></td>
                                            <td></td>
                                            <td className="s_action-column s_text-center "></td>
                                            <td className="s_tw-amount comman_class_table_th">
                                                <span className="table_total">
                                                    <span>Total:</span> <span>{ledgerTotalAmount.toFixed(2)}</span>
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="left_first_card">
                                <p className="left_card_titel">Tax Ledger Details</p>

                                <table className="s_transaction-content-box-table-border">
                                    <thead>
                                        <tr className="table_heading_tr common_tr">
                                            <th className="s_tw-5 comman_class_table_th">Sr .No</th>
                                            <th className="s_tw_35 comman_class_table_th">Tax Ledger Name</th>
                                            <th className="s_tw_30 comman_class_table_th">Tax Description</th>
                                            <th className="s_tw_15 comman_class_table_th">Tax Amount</th>
                                            <th className="s_action-column s_text-center comman_class_table_th">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {taxLedgerData.map((row, index) => (
                                            <tr key={index} className="table_body_tr common_tr">
                                                <td className="s_tw-5 comman_class_table_th">{row.id}</td>
                                                <td className="s_tw_35 comman_class_table_th">
                                                    <Select
                                                        className="voucher_type_select"
                                                        value={row.taxLedgerName}
                                                        onChange={(value) => handleTaxInputChange(index, "taxLedgerName", value)}
                                                        disabled
                                                    >
                                                        <Select.Option value="CGST">CGST</Select.Option>
                                                        <Select.Option value="SGST">SGST</Select.Option>
                                                    </Select>
                                                </td>
                                                <td className="s_tw_30 comman_class_table_th">
                                                    <Input
                                                        className="tax_description_input"
                                                        type="text"
                                                        value={row.taxDescription}
                                                        onChange={(e) => handleTaxInputChange(index, "taxDescription", e.target.value)}
                                                    />
                                                </td>
                                                <td className="s_tw_15 comman_class_table_th">
                                                    <Input
                                                        className="tax_amount_input"
                                                        type="text"
                                                        value={row.taxAmount}
                                                        onChange={(e) => handleTaxInputChange(index, "taxAmount", e.target.value)}
                                                    />
                                                </td>
                                                <td className="s_action-column s_text-center comman_class_table_th"></td>
                                            </tr>
                                        ))}
                                        <tr className="table_total_tr common_tr">
                                            <td></td>
                                            <td></td>
                                            <td className="s_tw_30 "></td>
                                            <td className="s_tw_15 "></td>
                                            <td className="s_action-column s_text-center comman_class_table_th">
                                                <span className="table_total">
                                                    <span>Total:</span> <span>{totalTaxAmount.toFixed(2)}</span>
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="left_last_card">
                                <div className="narration_left_div">
                                    <p className="left_card_titel">Narration</p>
                                    <textarea />
                                </div>
                                <div className="narration_right_div">
                                    <div className="narration_Total">
                                        <div className="narration_Total_div">
                                            <p>Sub Total :</p>
                                            <span>{itemTotalAmount + ledgerTotalAmount}</span>
                                        </div>
                                        <div className="narration_Total_div">
                                            <p>Tax Amount :</p>
                                            <span>{totalTaxAmount}</span>
                                        </div>
                                    </div>
                                    <div className="narration_Total_div total_bgm">
                                        <p>Total Amount :</p>
                                        <span>{itemTotalAmount + ledgerTotalAmount + totalTaxAmount}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="right_side_customer manual_ivc_card">
                            <div className="right_first_card">
                                <div className="first_card_heding">Customer Details</div>
                                <div className="details">
                                    <span>Customer Name:</span>
                                </div>
                                <div className="details">
                                    <span>Phone No:</span>
                                </div>
                                <div className="details">
                                    <span>Email:</span>
                                </div>
                                <div className="details">
                                    <span>Address:</span>
                                </div>
                                <textarea />
                            </div>
                            <div className="right_second_card">
                                <div className="right_second_card_div right_second_card_lefy">
                                    <p>Outstanding Receivables</p>
                                    <p>0</p>
                                </div>
                                <div className="right_second_card_div right_second_card_right">
                                    <span>Credit Days</span>
                                    <p>0</p>
                                </div>
                            </div>
                            <div className="right_third_card">
                                <div className="right_third_heading">Recent Invoices</div>
                                <div className="right_third_space">
                                    {formData && itemData && ledgerData && taxLedgerData ? (
                                        <pre>
                                            {JSON.stringify({ formData }, null, 2)}
                                            {"\n"}
                                            {JSON.stringify({ itemData }, null, 2)}
                                            {"\n"}
                                            {JSON.stringify({ ledgerData }, null, 2)}
                                            {"\n"}
                                            {JSON.stringify({ taxLedgerData }, null, 2)}
                                        </pre>
                                    ) : (
                                        <p>Data</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="invoice_footer_outer">
                        <div className="invoice-footer">
                            <Button className="invoice-btn" type="primary">
                                Previous
                            </Button>

                            <Button className="invoice-btn" type="primary" onClick={handleSave}>
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default withProfile(InvoiceTable);
