import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { DeleteOutlined, EllipsisOutlined, LoadingOutlined } from "@ant-design/icons";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Button, Dropdown } from "antd";
import { withProfile } from "../../contexts/profileContext";
import { withRole } from "../../contexts/roleContext";
import Spinner from "../../components/Common/Spinner/Spinner";
import { AgGridReact } from "ag-grid-react";
import withRouter from "../../components/Common/withRouter";

// import { io } from "socket.io-client";

// const socket = io(process.env.REACT_APP_SOCKET_API_URL);

const TcMainTable = ({ searchKeyword, validatedProfile, role, urlPathName, mainTableData, fetchData, isLoading, setIsLoading }) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [usersData, setUsersdData] = useState([]);
    const [colDefs, setColDefs] = useState([]);

    const navigate = useNavigate();
    const { pathnameWithSlash: currentPath, pathWithoutSlash, pathOnlyName } = urlPathName;

    const imageInvoicePaths = ["/tcsales", "/tcpurchase"];

    // Setup socket listeners
    // useEffect(() => {
    //     const userId = validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid;
    //     socket.on("connect", () => {
    //         console.log("Connected to the server");
    //     });

    //     socket.on("disconnect", () => {
    //         console.log("Disconnected from the server");
    //     });

    //     socket.on(`imageProcessCom`, (data) => {
    //         console.log("socket data", data);
    //     });
    //     socket.on(`imageProcessComplete${userId}`, (data) => {
    //         console.log("fetchData done");

    //         fetchData();
    //     });

    //     return () => {
    //         socket.off("connect");
    //         socket.off("disconnect");
    //         socket.off(`imageProcessComplete${userId}`);
    //     };
    // }, []); // Empty dependency array means it sets up listeners once on mount

    const callSocket = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/test-socket`);
            console.log("response", response);
        } catch (error) {
            console.error("Submission error:", error);
        }
    };
    useEffect(() => {
        if (mainTableData) {
            fetchTableData();
        }
    }, [mainTableData, validatedProfile?.companyId, pathWithoutSlash]);

    useEffect(() => {
        if (searchKeyword) {
            searchKeyword = "";
        }
    }, [pathWithoutSlash]);

    const fetchTableData = async () => {
        try {
            if (mainTableData) {
                const processedData = processDatas(mainTableData);

                setColDefs(generateColumns(processedData));

                setUsersdData(processedData?.reverse());
            }
        } catch (error) {
            console.error("Error fetching table data:", error);
        }
    };

    const processDatas = (data) => {
        return data[pathWithoutSlash]?.map((obj) =>
            Object.entries(obj).reduce((acc, [key, value]) => {
                acc[key.replace(/_/g, " ")] = value;
                return acc;
            }, {})
        );
    };

    const generateColumns = (data) => {
        if (!data || !data.length) return [];

        const indexColumns = [
            {
                headerName: "",
                field: "",
                width: 35,
                suppressHeaderMenuButton: true,
                headerCheckboxSelection: true,
                checkboxSelection: true,
                showDisabledCheckboxes: true,
            },
            {
                headerName: "Sr No",
                field: "Sr No",
                width: 70,
                suppressMenu: true,
                headerClass: "custom-header-center",
                valueGetter: (params) => params.node.rowIndex + 1,
                cellStyle: { display: "flex", justifyContent: "center", alignItems: "center" },
            },
        ].filter(Boolean);

        const fields = Object.keys(data[0]);
        const includedColumns = getIncludedColumns(pathWithoutSlash);
        const columns = fields
            .filter((field) => includedColumns.includes(field.toLowerCase()) && field !== "status" && field !== "convert status")
            .map((field) => ({
                field: field.replace(/_/g, " "),
                filter: false,
                flex: getFlexValue(field, pathWithoutSlash),
                cellRenderer: (params) => (params.value !== null ? params.value : "--"),
                suppressMenu: true,
            }));

        const convertStatusColumn = {
            headerName: "Convert Status",
            field: "convert_status",
            width: 140,
            cellRenderer: (params) => {
                const status = params.data["convert status"];

                return (
                    <span
                        className={
                            status === "Processing"
                                ? "statu_of_image_processing"
                                : status === "Review"
                                ? "statu_of_image_review"
                                : "statu_of_image_view"
                        }
                    >
                        {status === "Processing" ? <LoadingOutlined /> : null} {status}
                    </span>
                );
            },
            headerClass: "custom-header-center",
            cellStyle: { textAlign: "center" },
            suppressMenu: true,
        };

        const statusColumn = {
            headerName: "Status",
            field: "status",
            width: 120,
            cellRenderer: (params) => <span className={params.value}>{params.value}</span>,
            headerClass: "custom-header-center",
            cellStyle: { textAlign: "center" },
            suppressMenu: true,
        };

        const actionColumn = {
            headerName: "Action",
            field: "delete",
            suppressHeaderMenuButton: true,
            width: 80,
            headerClass: "custom-header-center",
            cellStyle: { textAlign: "center" },
            cellRenderer: (params) => {
                const isDeletable = role && ["D", "F"].includes(role[currentPath]) && params.data.status !== "Complete";

                const buttonClass = isDeletable
                    ? "text-danger maintable-deleterow Table-deleterow hide_btn_class"
                    : "text-success maintable-deleterow Table-deleterow hide_btn_class";

                const items = [
                    {
                        key: "delete1",
                        label: (
                            <Button
                                className={buttonClass}
                                type="danger"
                                disabled={!isDeletable || params.data.status === "Complete"}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    if (isDeletable) deleteSingleRow(params.data);
                                }}
                            >
                                <DeleteOutlined />
                            </Button>
                        ),
                    },
                ];

                return (
                    <>
                        <Button
                            className={buttonClass}
                            type="danger"
                            disabled={!isDeletable || params.data.status === "Complete"}
                            onClick={(event) => {
                                event.stopPropagation();
                                if (isDeletable) deleteSingleRow(params.data);
                            }}
                        >
                            <DeleteOutlined />
                        </Button>
                        <Button className="action_dropdown_icon" type="danger">
                            <Dropdown menu={{ items }} placement="bottomRight" arrow trigger={["click"]}>
                                <a onClick={(e) => e.preventDefault()}>
                                    <EllipsisOutlined style={{ fontSize: "24px", color: "#1890ff", transform: "rotate(90deg)" }} />
                                </a>
                            </Dropdown>
                        </Button>
                    </>
                );
            },
        };

        return [...indexColumns, convertStatusColumn, ...columns, statusColumn, actionColumn];
    };

    const getIncludedColumns = (path) => {
        switch (path) {
            case "tcsales":
                return [
                    "name",
                    "convert status",
                    "filetype",
                    "party ac name",
                    "imagetype",
                    "total amount",
                    "pending",
                    "save",
                    "total",
                    "synced",
                    "voucher no",
                    "voucher type",
                    "statement date",
                    "synced date",
                    "status",
                ];
            case "tcpurchase":
                return [
                    "name",
                    "convert status",
                    "filetype",
                    "party ac name",
                    "imagetype",
                    "total amount",
                    "pending",
                    "save",
                    "total",
                    "synced",
                    "voucher no",
                    "voucher type",
                    "statement date",
                    "synced date",
                    "status",
                ];
            default:
                return [];
        }
    };

    const getFlexValue = (field, path) => {
        const flexMap = {
            banking: {
                name: 4,
            },
        };
        return flexMap[path]?.[field.toLowerCase()] || 1;
    };

    const deleteSingleRow = async (rowData) => {
        let deleteFile = window.confirm(`Are you want to delete "${rowData.name}" file?`);
        if (deleteFile) {
            const deleteID = rowData.srno;
            try {
                const { status } = await axios.post(`${process.env.REACT_APP_API_URL}/deleteData`, {
                    userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
                    companyId: validatedProfile.companyId,
                    tablename: pathWithoutSlash,
                    deleteID: [deleteID],
                });
                if (status === 200) {
                    fetchData();
                    fetchTableData();
                }
            } catch (error) {
                console.error("Error deleting row:", error);
            }
        }
    };

    const deleteSelectedRows = async () => {
        let deleteFile = window.confirm(`Are you want to delete all selected file?`);
        if (deleteFile) {
            try {
                const { status } = await axios.post(`${process.env.REACT_APP_API_URL}/deleteData`, {
                    userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
                    companyId: validatedProfile.companyId,
                    tablename: pathWithoutSlash,
                    deleteID: selectedRowKeys,
                });
                if (status === 200) {
                    fetchData();
                    fetchTableData();
                }
            } catch (error) {
                console.error("Error deleting selected rows:", error);
            }
        }
    };

    const renderDeleteButton = () =>
        selectedRowKeys.length >= 2 &&
        role &&
        ["D", "F"].includes(role[currentPath]) && (
            <Button type="primary" className="deleteSelectedRow" onClick={deleteSelectedRows}>
                Delete Selected Row
            </Button>
        );

    const onSelectionChanged = (params) => {
        const selectedIds = params.api.getSelectedRows().map((row) => row.srno);
        setSelectedRowKeys(selectedIds);
    };

    const isRowSelectable = useCallback((params) => {
        // console.log("params", params);
        // apply condition for select checkbox enable or disable
        return false;
    }, []);

    return (
        <>
            <Spinner isSpinner={isLoading} />
            {/* <button onClick={callSocket}>Test Socket</button> */}
            <div className="ag-theme-alpine ag-main-table themeQuartz">
                <AgGridReact
                    className="main_table_ag"
                    rowData={usersData}
                    isRowSelectable={isRowSelectable}
                    columnDefs={colDefs}
                    pagination
                    rowSelection="multiple"
                    paginationPageSize={20}
                    paginationPageSizeSelector={[10, 20, 50, 100]}
                    gridOptions={generateColumns}
                    onCellClicked={(params) => {
                        if (
                            params.colDef.field !== "delete" &&
                            params.data["convert status"] !== "Processing" &&
                            imageInvoicePaths.includes(currentPath)
                        ) {
                            navigate("/imageinvoicetable", {
                                state: {
                                    viewStatus: params.data["convert status"],
                                    rawImageJson: params.data["rawimagejson"],
                                    doc_id: params.data["doc id"],
                                    iamgeName: params.data["name"],
                                    currentPath,
                                    rowkey: params.data.srno,
                                    pathWithoutSlash,
                                    pathOnlyName,
                                },
                            });
                        }
                    }}
                    onSelectionChanged={onSelectionChanged}
                    overlayNoRowsTemplate="<span>No data available</span>"
                />
                {renderDeleteButton()}
            </div>
        </>
    );
};

export default withProfile(withRole(withRouter(TcMainTable)));
