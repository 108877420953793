import React from "react";
// import TableAll from "../../../../Layout/MainTable/Table";
import TcMainTable from "../../../../Layout/TcMainTable";

const Sales = () => {
    return (
        <>
            <div className="page-content">
                {/* <TableAll /> */}
                <TcMainTable />
            </div>
        </>
    );
};

export default Sales;
