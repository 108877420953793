import { Button, Empty, Input, Modal, Select, Table } from "antd";
import React, { useState, useEffect } from "react";

import axios from "axios";
import { useRef } from "react";
import { CheckCircleFilled } from "@ant-design/icons";

const TallySyncTable = ({
    tallySyncModalOpen,
    validatedProfile,
    setTallySyncModalOpen,
    pathPlusData,
    selectedTallySyncId,
    RowKey,
    fetchSubTableData,
    PSItem,
    SNItem,
    SYItem,
    ERItem,
    viewAllsyncedFlag,
}) => {
    const total = selectedTallySyncId.length;

    let flagForCall = 0;

    useEffect(() => {
        if (tallySyncModalOpen && flagForCall === 0 && !RowKey) {
            sendtoTally();
            flagForCall = 1;
        }
    }, []);

    const intervalRef = useRef(null);

    useEffect(() => {
        if (total === SYItem + ERItem) {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                fetchSubTableData();
            }
            return;
        }

        intervalRef.current = setInterval(() => {
            viewAllsyncedFlag();
        }, 3000);

        return () => {
            clearInterval(intervalRef.current);
        };
    }, [PSItem, SNItem, SYItem, ERItem]);

    const sendtoTally = () => {
        let data = selectedTallySyncId.map((row, index) => {
            return {
                id: row.id,
                flag: "PS",
            };
        });

        let payload = {
            userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
            companyId: validatedProfile.companyId,
            tableName: pathPlusData,
            idColumnName: "id",
            data: data,
        };

        axios
            .post(`${process.env.REACT_APP_API_URL}/update-flag`, payload)
            .then((response) => {
                // console.log("flag response.data", response.data);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    return (
        <>
            <Modal className="TallySyncTable-model" open={tallySyncModalOpen} footer={null} width={1000}>
                <div className="TallySyncTable-outer">
                    <div className="TallySyncTable-inner">
                        <div className="TallySyncTable-header">
                            <p className="TallySyncTable-header-text">Your transaction are in progress</p>
                        </div>
                        <div className="TallySyncTable-body">
                            <ul>
                                <li className="TallySyncTable-body-text">
                                    <span>We are saving your transactions to tally,please follow below steps:</span>
                                    <ul>
                                        <li className="TallySyncTable-body-text-sub">
                                            Open <span>Tax-link's Tally connector app</span> and <span>Tally</span> on your pc/laptop
                                        </li>
                                        <li className="TallySyncTable-body-text-sub">
                                            Please make sure that you have selected company in Tally for which your saving data
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <table>
                                        <thead>
                                            <tr className="TallySyncTable_header_tr">
                                                <th>Total Saved Transaction(s)</th>
                                                <th>Sync Started</th>
                                                <th>In Progress</th>
                                                <th>Synced In Tally</th>
                                                <th>Error From Synced</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="TallySyncTable_body_tr">
                                                <td>{total}</td>
                                                <td>{PSItem}</td>
                                                <td>{SNItem}</td>
                                                <td>{SYItem}</td>
                                                <td>{ERItem}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </li>

                                {/* <li className="TallySyncTable-body-text success-msg text-warning d-flex"> */}
                                <li className="TallySyncTable-body-text success-msg  d-flex" style={{ color: "rgba(255, 124, 30, 1)" }}>
                                    <CheckCircleFilled className={`${total === SYItem + ERItem ? "text-muted" : "text-primary"}`} />
                                    <ul>
                                        <li>{total} Transactions synced in Tax-link (Tally Connector App)</li>
                                        <li>
                                            (Please make sure that tally is runnung on your machine and {validatedProfile.companyName} is
                                            selected.)
                                        </li>
                                    </ul>
                                </li>

                                <li className="TallySyncTable-body-text success-msg text-success">
                                    <CheckCircleFilled className={`${total === SYItem + ERItem ? "text-success" : "text-muted"}`} />
                                    <span>{SYItem} Transactions successfuly synced in Tally</span>
                                </li>

                                <li className="TallySyncTable-body-text success-msg text-danger">
                                    <CheckCircleFilled className={`${total === SYItem + ERItem ? "text-danger" : "text-muted"}`} />
                                    <span>{ERItem} Transactions fail synced in Tally</span>
                                </li>

                                <li className="TallySyncTable-body-text">
                                    You'll be able to resume your work on this statement right afterthis sync is done.(if any)
                                </li>
                            </ul>
                        </div>
                        <div className="TallySyncTable-footer">
                            <Button
                                onClick={() => setTallySyncModalOpen(false)}
                                type="primary"
                                style={{ backgroundColor: "rgb(22, 208, 200)", padding: "0px 15px", height: "40px" }}
                            >
                                back to Transaction List
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default TallySyncTable;
