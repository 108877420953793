import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { YoutubeOutlined, SnippetsOutlined, CaretDownFilled, UserAddOutlined, SyncOutlined, DownloadOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Dropdown, Menu } from "antd";
import { withGeneral } from "../../contexts/generalContext";
import { withRole } from "../../contexts/roleContext";
import { withProfile } from "../../contexts/profileContext";
import axios from "axios";

const TopBar = ({ opencomponent, role, validatedProfile }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;
    const pathWithoutSlash = currentPath.substring(1);

    const uploadButtonAvilablePath = [
        "/bulk-upload",
        "/sales/excel",
        "/banking",
        "/sales-return",
        "/purchase",
        "/purchase-return",
        "/journal",
        "/ledgerbulk",
        "/item/excel",
    ];
    const pathsToCheckbulk = [
        "/bulk-upload",
        "/sales/excel",
        "/banking",
        "/sales-return",
        "/purchase",
        "/purchase-return",
        "/journal",
        "/ledgerbulk",
        "/item/excel",
    ];

    const pathsToChecksettings = [
        "/settings",
        "/adduser",
        "/my-profile",
        "/my-plan",
        "/users",
        "/user-report",
        "/refer-earn",
        "/role-management",
    ];
    const pathsToCheckmaster = [
        "/master",
        "/ledgermaster",
        "/item",
        "/ruelist",
        "/leadgergroupnames",
        "/ledgernames",
        "/stockgroup",
        "/stockitem",
        "/unit",
        "/vouchertype",
    ];
    const pathsToChecktransactions = ["/transactions", "/tcpayment", "/tcsales", "/tcpurchase", "/tcpayment", "/tcreceipt", "/tccontra"];
    const pathsToCheckMyCompany = ["/my-companies", "/add-companies"];
    const pathsToCheckAutosale = ["/autosale"];
    const pathsToCheckAutoExpenses = ["/autoexpenses"];

    const getItemsBasedOnPath = () => {
        const pathMappings = [
            { paths: pathsToCheckbulk, component: BulkUpload },
            { paths: pathsToChecksettings, component: Settings },
            { paths: pathsToCheckmaster, component: Master },
            { paths: pathsToChecktransactions, component: Transactions },
            { paths: pathsToCheckMyCompany, component: MyCompany },
            { paths: pathsToCheckAutosale, component: Autosale },
            { paths: pathsToCheckAutoExpenses, component: AutoExpenses },
        ];

        const match = pathMappings.find(({ paths }) => paths.some((path) => currentPath.startsWith(path)));

        return match ? match.component : [];
    };

    const BulkUpload = [
        { link: "/banking", component: "Banking" },
        { link: "/sales/excel", component: "Sales" },
        // { link: "/sales-return", component: "Sales-Return" },
        { link: "/purchase", component: "Purchase" },
        // { link: "/purchase-return", component: "Purchase-Return" },
        // { link: "/journal", component: "Journal" },
        { link: "/ledgerbulk", component: "Ledger Bulk" },
        { link: "/item/excel", component: "Items" },
    ];

    const Autosale = [{ link: "/autosale", component: "Auto Sale" }];
    const AutoExpenses = [{ link: "/autoexpenses", component: "Auto Expenses" }];

    const Settings =
        validatedProfile.userType === "S"
            ? [{ link: "/my-profile", component: "My Profile" }]
            : [
                  { link: "/my-profile", component: "My Profile" },
                  { link: "/my-plan", component: "My Plan" },
                  { link: "/users", component: "Users" },
                  { link: "/refer-earn", component: "Refer Earn" },
                  { link: "/role-management", component: "Role Management" },
              ];

    const Master = [
        { link: "/ledgermaster", component: "Ledger Master" },
        { link: "/item", component: "Item Master" },
        // { link: "/ruelist", component: "Rule List" },
        // { link: "/leadgergroupnames", component: "leadgergroupnames" },
        // { link: "/ledgernames", component: "ledgernames" },
        // { link: "/stockgroup", component: "stockgroup" },
        // { link: "/stockitem", component: "stockitem" },
        // { link: "/unit", component: "unit" },
        // { link: "/vouchertype", component: "vouchertype" },
    ];

    const Transactions = [
        { link: "/tcsales", component: "Sales" },
        { link: "/tcpurchase", component: "Purchase" },
        // { link: "/tcpayment", component: "Payment" },
        // { link: "/tcreceipt", component: "Receipt" },
        // { link: "/tccontra", component: "Contra" },
    ];

    const MyCompany = [
        { link: "/my-companies", component: "My Companies" },
        { link: "/add-companies", component: "Add Companies" },
    ];

    // const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
    // const [activeItem, setActiveItem] = useState(0);
    // const handleItemClick = (index) => {
    //   setActiveItem(index);
    // };

    const [dropdownVisible, setDropdownVisible] = useState(false);

    const handleButtonClick = () => {
        setDropdownVisible(!dropdownVisible); // Toggle dropdown visibility
    };

    const downloadSampleFileButtonClick = async (fileName) => {
        setDropdownVisible(!dropdownVisible); // Toggle dropdown visibility

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/downloadsamplefile?filename=${fileName}`, {
                responseType: "blob", // Ensure the response is a blob
            });

            // Create a URL for the file
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName); // Suggested file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // Cleanup
        } catch (error) {
            console.error("Error downloading file:", error);
            alert("Error downloading file. Please try again.");
        }
    };

    const menu = (
        <div className="topbar-download-btn">
            <Menu onClick={() => setDropdownVisible(false)}>
                <Menu.Item key="item1" onClick={() => downloadSampleFileButtonClick("sales-sample.xlsx")}>
                    <a href="#">Sales Sample</a>
                </Menu.Item>
                <Menu.Item key="item2" onClick={() => downloadSampleFileButtonClick("KAGZI-JAN-MAR-24-1 gst new dl.xlsx")}>
                    <a href="#">Other Sample File</a>
                </Menu.Item>
            </Menu>
        </div>
    );
    const matchedTransaction = Transactions.find((transaction) => currentPath.includes(transaction.link));
    const imageUploadMenu = (
        <div className="topbar-download-btn">
            <Menu onClick={() => setDropdownVisible(false)}>
                <Menu.Item key="item1" onClick={() => opencomponent(pathWithoutSlash)}>
                    {matchedTransaction && <p>{matchedTransaction.component} Image</p>}
                </Menu.Item>
            </Menu>
        </div>
    );

    const renderUploadButton = () => {
        for (let rolekey in role) {
            if (
                uploadButtonAvilablePath.some((path) => path === currentPath) &&
                currentPath === rolekey &&
                (role[rolekey] === "F" || role[rolekey] === "CR")
            ) {
                return (
                    <div className="upload-btn all-div-margin" key={rolekey}>
                        <button
                            className="bulkupload-topbar-btn bulkupload-btn btn-primary border-primary"
                            onClick={() => opencomponent(pathWithoutSlash)}
                        >
                            <DownloadOutlined style={{ transform: "rotate(180deg)" }} /> Upload
                        </button>
                    </div>
                );
            }
        }
        return null;
    };

    useEffect(() => {
        const items = getItemsBasedOnPath();
        let shouldNavigate = true;

        items.forEach((component) => {
            for (let rolekey in role) {
                if (component.link === rolekey && role[rolekey] !== null) {
                    if (
                        (currentPath === "/bulk-upload" ||
                            currentPath === "/master" ||
                            currentPath === "/transactions" ||
                            currentPath === "/settings") &&
                        shouldNavigate
                    ) {
                        navigate(component.link);
                        shouldNavigate = false;
                    }
                }
            }
        });
    }, [currentPath, role, navigate]);

    return (
        <div className="bulkupload-topbar">
            <div className="bulkupload-topbar-inner">
                <div className="bulkupload-topbar-header">
                    <div className="bulkupload-topbar-header-ul d-flex">
                        {getItemsBasedOnPath().map((component, index) => {
                            const matchingRoleKey = role && Object.keys(role).find((rolekey) => component.link === rolekey);

                            if (matchingRoleKey && validatedProfile.userType === "S") {
                                if (role[matchingRoleKey] !== null) {
                                    const lowercasePath = component.link;
                                    return (
                                        <li key={index} className={`bnk-hd-li ${currentPath === lowercasePath ? "active" : ""}`}>
                                            <Link className="bnk-hd-li-link" to={component.link}>
                                                {component.component}
                                            </Link>
                                        </li>
                                    );
                                }
                            } else {
                                const lowercasePath = component.link;
                                return (
                                    <li key={index} className={`bnk-hd-li ${currentPath === lowercasePath ? "active" : ""}`}>
                                        <Link className="bnk-hd-li-link" to={component.link}>
                                            {component.component}
                                        </Link>
                                    </li>
                                );
                            }

                            return null;
                        })}
                    </div>
                    <div className="bulkupload-topbar-header-btn">
                        {currentPath === "/users" && (
                            <div className="upload-btn all-div-margin">
                                <Link to="/adduser" className="btn-text">
                                    <button className="bulkupload-topbar-btn bulkupload-btn btn-primary border-primary">
                                        <UserAddOutlined className="text-success pr-1" />
                                        Add User
                                    </button>
                                </Link>
                            </div>
                        )}
                        {currentPath === "/my-companies" && (
                            <div className="upload-btn all-div-margin">
                                <button className="bulkupload-topbar-btn bulkupload-btn btn-primary border-primary">
                                    <SyncOutlined className="text-success pr-1" />
                                    Sync Companies
                                </button>
                            </div>
                        )}
                        {pathsToCheckbulk.some((path) => currentPath.startsWith(path)) && (
                            <ConfigProvider
                                theme={{
                                    token: {
                                        colorPrimary: "#0bb197",
                                    },
                                }}
                            >
                                <div className="download-btn all-div-margin">
                                    <Dropdown
                                        overlay={menu}
                                        open={dropdownVisible}
                                        onOpenChange={(visible) => setDropdownVisible(visible)}
                                        trigger={["click"]}
                                    >
                                        <Button onClick={handleButtonClick} className="Download-Sample-btn">
                                            Download Sample <CaretDownFilled className="header-admin-icon w-5" />
                                        </Button>
                                    </Dropdown>
                                </div>
                            </ConfigProvider>
                        )}
                        {pathsToChecktransactions.some((path) => currentPath.startsWith(path)) && (
                            <ConfigProvider
                                theme={{
                                    token: {
                                        colorPrimary: "#0bb197",
                                    },
                                }}
                            >
                                <div className="download-btn all-div-margin">
                                    <Dropdown
                                        overlay={imageUploadMenu}
                                        open={dropdownVisible}
                                        onOpenChange={(visible) => setDropdownVisible(visible)}
                                        trigger={["click"]}
                                    >
                                        <Button disabled onClick={handleButtonClick} className="Download-Sample-btn">
                                            Image Upload <CaretDownFilled className="header-admin-icon w-5" />
                                        </Button>
                                    </Dropdown>
                                </div>
                            </ConfigProvider>
                        )}
                        {renderUploadButton()}

                        {(currentPath === "/tcsales" || currentPath === "/tcpurchase") && (
                            <div className="upload-btn all-div-margin">
                                <Button
                                    // disabled
                                    className="bulkupload-topbar-btn bulkupload-btn btn-primary border-primary"
                                    onClick={() =>
                                        navigate("/invoicetable", {
                                            state: { currentPath: currentPath, pathWithoutSlash: pathWithoutSlash },
                                        })
                                    }
                                >
                                    <DownloadOutlined style={{ transform: "rotate(180deg)" }} /> Create Bill
                                </Button>
                            </div>
                        )}
                        {currentPath === "/autosale" && (
                            <div className="upload-btn all-div-margin">
                                <button
                                    className="bulkupload-topbar-btn bulkupload-btn btn-primary border-primary"
                                    onClick={() => opencomponent(pathWithoutSlash)}
                                >
                                    <DownloadOutlined style={{ transform: "rotate(180deg)" }} /> Add Autosale
                                </button>
                            </div>
                        )}
                        {currentPath === "/autoexpenses" && (
                            <div className="upload-btn all-div-margin">
                                <button
                                    className="bulkupload-topbar-btn bulkupload-btn btn-primary border-primary"
                                    onClick={() => opencomponent(pathWithoutSlash)}
                                >
                                    <DownloadOutlined style={{ transform: "rotate(180deg)" }} /> Add Expenses
                                </button>
                            </div>
                        )}
                        <div className="youtube-btn all-div-margin">
                            <button className="bulkupload-topbar-btn bulkupload-btn btn-outline-danger bg-btn">
                                {/* <YoutubeOutlined className="topobar-icon text-danger" /> */}
                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0 0 48 48">
                                    <path
                                        fill="#FF3D00"
                                        d="M43.2,33.9c-0.4,2.1-2.1,3.7-4.2,4c-3.3,0.5-8.8,1.1-15,1.1c-6.1,0-11.6-0.6-15-1.1c-2.1-0.3-3.8-1.9-4.2-4C4.4,31.6,4,28.2,4,24c0-4.2,0.4-7.6,0.8-9.9c0.4-2.1,2.1-3.7,4.2-4C12.3,9.6,17.8,9,24,9c6.2,0,11.6,0.6,15,1.1c2.1,0.3,3.8,1.9,4.2,4c0.4,2.3,0.9,5.7,0.9,9.9C44,28.2,43.6,31.6,43.2,33.9z"
                                    ></path>
                                    <path fill="#FFF" d="M20 31L20 17 32 24z"></path>
                                </svg>
                            </button>
                        </div>
                        <div className="document-btn all-div-margin">
                            <button className="bulkupload-topbar-btn bulkupload-btn btn-outline-primary bg-btn">
                                {/* <SnippetsOutlined className="topobar-icon" /> */}
                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0 0 48 48">
                                    <path fill="#90CAF9" d="M40 45L8 45 8 3 30 3 40 13z"></path>
                                    <path fill="#E1F5FE" d="M38.5 14L29 14 29 4.5z"></path>
                                    <path fill="#1976D2" d="M16 21H33V23H16zM16 25H29V27H16zM16 29H33V31H16zM16 33H29V35H16z"></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withGeneral(withProfile(withRole(TopBar)));
