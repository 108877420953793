import axios from "axios";

const imageToJsonFcn = async (image, validatedProfile, pathOnlyName, pathWithoutSlash, currentPath, setIsSpinner, fetchData, navigate) => {
    const data = {
        userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
        companyId: validatedProfile.companyId,
        fileDetails: {
            name: image.name,
            filetype: "invoice",
            imagetype: image.type,
            convert_status: "Uploading",
        },
        tableName: pathWithoutSlash,
    };

    const formData = new FormData();
    formData.append("image", image);
    formData.append("data", JSON.stringify(data));

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/tcinsertimagewithfiledata`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
        });
        console.log("response", response);
    } catch (error) {
        setIsSpinner(false);
        console.error("Submission error:", error);
    }
};

export { imageToJsonFcn };
